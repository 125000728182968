import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-permissions--permissions-selection"
export default class extends Controller {

  static targets = [ "selection" ]

  connect() {
    this.selectionTargets.forEach(selection => {
      selection.addEventListener('change', this.selectionChange.bind(this));
      this.toggleCheckboxesWithoutAll();
      this.highlightAllCheckbox();
    })
  }

  selectionChange(event) {
    if (event.target.value === 'all') {
      this.toggleCheckboxesWithoutAll();
    }
  }

  // Make the 'All' checkbox red
  highlightAllCheckbox() {
    this.selectionTarget.querySelector('input[value="all"]').parentElement.classList.add('text-danger')
  }

  toggleCheckboxesWithoutAll() {
    const checked = this.selectionTarget.querySelector('input[value="all"]').checked
    this.selectionTarget.querySelectorAll('.form-check-input:not([value="all"])').forEach(s => {
      if (checked) {
        s.setAttribute('disabled', 'true')
      } else {
        s.removeAttribute('disabled')
      }
    })
  }
}
