import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="payment-requests--form"
export default class extends Controller {
  connect() {}

  updateVendor() {
    // 1. Fetch department_id, payment_method, vendor_name, vendor_id data
    let department_id = document.querySelector("#payment_request_department_id")?.value;
    let payment_method = document.querySelector("#payment_request_payment_method")?.value;
    let vendor_name = document.querySelector("#payment_request_vendor_name")?.value;
    let vendor_id = document.querySelector("#payment_request_vendor_id")?.value;

    // 2. Send vendor_eip_payment_requests_path turbo stream request
    get("/payment_requests/vendor", {
      query: {
        department_id: department_id,
        payment_method: payment_method,
        vendor_name: vendor_name,
        vendor_id: vendor_id,
      },
      responseKind: "turbo-stream",
    });
  }
}
