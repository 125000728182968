
export const isMoment = (date) => (date instanceof moment)

export const mParseDate = (date) =>
  isMoment(date) ? date : moment(date, ['YYYY/MM/DD', 'YYYY-MM-DD'])

export const mFormat = (date, format) => mParseDate(date).locale("zh-tw").format(format)

export const isSameMonth = (date, another) => {
  const date1 = mParseDate(date), date2 = mParseDate(another)
  return date1.diff(date2, 'month') == 0
}

export const isFuture = (time) => mParse(time).diff(moment()) > 0
