import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element);
    this.modal.show();
    $(this.modal).find("[autofocus]").focus();
    Turbo.cache.exemptPageFromCache();
  }

  close() {
    this.modal.hide();
  }

  closeOnSuccess(event) {
    const fetchResponse = event.detail.fetchResponse
    if(fetchResponse && fetchResponse.statusCode === 200) {
      this.modal.hide();
    }
  }

  handleResponse(event) {
    const fetchResponse = event.detail.fetchResponse
    if(fetchResponse && fetchResponse.statusCode === 200) {
      Turbo.visit(fetchResponse.response.url)
    }
  }
}
