export const DATE_FORMAT = "YYYY/MM/DD"
export const DATE_SEPARATOR = ' ~ '

export const DATERANGEPICKER_LOCALE = {
  "format": DATE_FORMAT,
  "separator": DATE_SEPARATOR,
  "applyLabel": "套用",
  "cancelLabel": "取消",
  "fromLabel": "From",
  "toLabel": "To",
  "customRangeLabel": "自訂",
  "weekLabel": "週",
  "daysOfWeek": [
      "日",
      "一",
      "二",
      "三",
      "四",
      "五",
      "六"
  ],
  "monthNames": [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月"
  ],
  "firstDay": 1
}

export const AVAILABLE_DATE_FORMATS = ['YYYY/MM/DD', 'YYYY-MM-DD']

export const DEFAULT_VALUES = {
  plaintext: { type: Boolean, default: false },
  autoSubmit: { type: Boolean, default: false },
  dateFormat: { type: String, default: 'YYYY/MM/DD' }, // daterangepicker (moment.js)
  currentParams: Object,
  allowFuture: { type: Boolean, default: true },
  allowPast: { type: Boolean, default: true },
  autoUpdateInput: { type: Boolean, default: false },
}

export const DEFAULT_VALUES_MONTHLY = {
  ...DEFAULT_VALUES,
  dateFormat: { type: String, default: 'yyyy/MM' } // air-datepicker
}
