import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sms-message"
export default class extends Controller {
  static targets = [ "smsMessage"]

  computed() {
    const length_per_sms = 70
    let length = $(this.smsMessageTarget).val().length
    let helperHtml = ''
    if (length > 0) {
      const sms_count = Math.ceil(length / length_per_sms)
      helperHtml = `<small id="smsNoticeMessage"> 目前字數 ${length} 字 ( 每 70 字 / 1 封 )，每 1 筆將發送 ${sms_count} 封簡訊</small>`
    }
    if ($('#smsNoticeMessage').length == 0){
      $(this.smsMessageTarget).after(helperHtml)
    }else{
      $('#smsNoticeMessage').replaceWith(helperHtml)
    }
    return ''
  }
}