import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="requisit-form"
export default class extends Controller {
  // Define targets
  static targets = ["shippingMethod", "shippingInfo"]

  // Get shipping method value
  get shippingMethod() {
    return this.shippingMethodTarget.value
  }

  // Called when controller is connected
  connect() {
    this._toggleShippingInfo()
  }

  // Called when shipping method is changed
  changeShippingInfo() {
    this._toggleShippingInfo()
  }

  // Toggle shipping info
  _toggleShippingInfo() {
    // Check shipping method equal to "address"
    // If true, show address fields, else hide
    if (this.shippingMethod) {
      if (this.shippingMethod === "address") {
        this.shippingInfoTarget.classList.remove("d-none")
      } else {
        this.shippingInfoTarget.classList.add("d-none")
      }
    }
  }
}
