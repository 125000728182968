import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = [ "link", "panel", "tab" ]

  panelTargetConnected(elem) {
    elem.dataset.cache = elem.innerHTML
    if (elem.dataset.active == undefined) {
      elem.innerHTML = ""
    }
  }

  activeTab(target) {
    this.tabTargets.forEach(elem => {
      if (elem.type == "radio") {
        elem.checked = (elem.dataset.tab == target.dataset.tab)
      } else if (elem.tagName == "A") {
        const method = elem.dataset.tab == target.dataset.tab ? "add" : "remove"
        elem.classList[method]("active")
      } else {
        throw "target tab require <a herf=\"\" or <input type=\"radio\" "
      }
    })
  }

  switch(e) {
    this.activeTab(e.target)

    this.panelTargets.forEach(elem => {
      if (elem.dataset.panel == e.target.dataset.tab) {
        elem.innerHTML = elem.dataset.cache
      } else {
        elem.innerHTML = ""
      }
    })
  }
}
