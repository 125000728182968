import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="highlight"
export default class extends Controller {
  connect() {
    this.element.classList.add("table-primary");
    this.timeout = setTimeout(
      () => this.element.classList.remove("table-primary"),
      3000
    );
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
