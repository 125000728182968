import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link"
export default class extends Controller {
  static targets = [ "link" ]

  newPage() {
    const redirect_url = $(this.linkTarget).data('redirect-url')
    window.open(redirect_url, '_blank').focus();
  }
}
