import { Controller } from "@hotwired/stimulus"
import { round } from "@popperjs/core/lib/utils/math"

// Connects to data-controller="contract-applications--stamp-tax-edit"
export default class extends Controller {

  static targets = [ "amount", "taxAmount" ]

  connect() {
  }

  typeChange(event) {
    const type = event.target.value
    const amount = this.amountTarget.value
    if (type == 'no_need') {
      this.taxAmountTarget.value = 0
    } else if (type == 'contract') {
      // 承攬契據每件未稅金額0.1%，不滿1元不需繳納
      this.taxAmountTarget.value = round(amount / 1000)
    } else if (type == 'deeds_for_sale') {
      // 買賣契約每件12元
      this.taxAmountTarget.value = 12
    } else {
      this.taxAmountTarget.value = 0
    }
  }

  editClick() {
    this.taxAmountTarget.removeAttribute('readonly')
    this.taxAmountTarget.classList.remove('form-control-plaintext')
    this.taxAmountTarget.focus()
  }
}
