import { Controller } from "@hotwired/stimulus"
import {
  DATERANGEPICKER_LOCALE,
  DATE_FORMAT,
  DATE_SEPARATOR,
  DEFAULT_VALUES
} from './consts'
import { mParseDate, mFormat, isMoment, linkTo } from 'utils'

const defaultRanges = () => {
  let ranges = {}
  const startYear = moment().startOf('year')
  Array(12).fill(0).forEach((_, i) => {
    const month = startYear.clone().add(i, 'month')
    ranges[
      `${month.year()}, ${DATERANGEPICKER_LOCALE.monthNames[month.month()]}`
    ] = [
      month.clone().startOf('month'),
      month.clone().endOf('month')
    ]
  });
  return ranges
}

const rangesToOptions = (values, opts = {}) => {
  const { minDate, maxDate } = opts
  let entries = []

  const _isDateValid = (time, max, min) => {
    if (min && max) {
      return time.isBetween(min, max)
    }

    if (max) {
      return time.isBefore(max)
    }

    if (min) {
      return time.isAfter(min)
    }

    // No max & min
    return true
  }


  Object.entries(values).forEach(([key, [ startDate, endDate ]]) => {
    let dates
    let momentFrom  = mParseDate(startDate)
    let momentTo    = mParseDate(endDate)

    let isValidStartDate  = _isDateValid( momentFrom,
                                          maxDate ? mParseDate(maxDate) : null,
                                          minDate ? mParseDate(minDate) : null)
    let isValidEndDate    = _isDateValid( momentTo,
                                          maxDate ? mParseDate(maxDate) : null,
                                          minDate ? mParseDate(minDate) : null)

    if (isValidStartDate && isValidEndDate) {
      dates = [ momentFrom, momentTo ]

    } else if (isValidStartDate) {
      dates = [ momentFrom, mParseDate(maxDate) ]

    } else if (isValidEndDate) {
      dates = [ mParseDate(minDate), momentTo ]

    } else {
      // Do nothing
    }

    if (dates) {
      entries.push([ key, dates ])
    }
  })
  return Object.fromEntries(entries)
}

// Connects to data-controller="date-picker--date-range"
export default class extends Controller {
  static targets = [ 'visual', 'begin', 'end' ]
  static values = {
    ...DEFAULT_VALUES,
    defaultRange: Boolean,
    ranges: Object
  }

  beginTargetConnected() {
    const date = mParseDate(this.beginTarget.value)
    if (date.isValid()) { this.startDate = date }
  }

  endTargetConnected() {
    const date = mParseDate(this.endTarget.value)
    if (date.isValid()) { this.endDate = date }
  }

  connect() {
    const opts = {
      locale: this.getDpLocale(),
      alwaysShowCalendars: true,
      startDate: this.startDate,
      endDate: this.endDate,
      autoApply: true,
      showCustomRangeLabel: false,
      drops: 'auto',
      showDropdowns: true,
      autoUpdateInput: false,
      maxDate: !this.allowFutureValue && moment(),
      minDate: !this.allowPastValue && moment()
    }
    // ranges
    if (this.defaultRangeValue) {
      opts['ranges'] = defaultRanges()
    } else if (Object.keys(this.rangesValue).length > 0) {
      opts['ranges'] = rangesToOptions(this.rangesValue, {
        minDate: opts.minDate,
        maxDate: opts.maxDate
      })
    }
    this.dp = new DateRangePicker(this.element, opts, this.onApply.bind(this))
    this.dp.element.on('keyup.daterangepicker', this.onManualChanged.bind(this))

    this.setVisualSize()
    this.setVisualInput(this.startDate, this.endDate)
  }

  rangesValueToOptions() {
    const _toOption = ([key, dates]) => [key, dates.map((date) => mParseDate(date))]
    return Object.fromEntries(Object.entries(this.rangesValue).map(_toOption))
  }

  getVisualText(startDate = moment(), endDate = moment()) {
    return this.dateFormat(startDate) + DATE_SEPARATOR + this.dateFormat(endDate)
  }

  setVisualSize() {
    if (this.plaintextValue) {
      this.visualTarget.style.width = this.getVisualText().length + 'ch'
    }
  }

  setVisualInput(startDate, endDate) {
    if (isMoment(startDate) && isMoment(endDate)) {
      this.visualTarget.value = this.getVisualText(startDate, endDate)
    } else {
      this.visualTarget.value = ''
    }
  }

  updateBeginAndEndInput(startDate, endDate) {
    this.beginTarget.value  = this.dateFormat(startDate)
    this.endTarget.value    = this.dateFormat(endDate)
  }

  setBeginInput(date) {
    this.beginTarget.value = this.dateFormat(date)
  }

  setEndInput(date) {
    this.endTarget.value = this.dateFormat(date)
  }

  onApply(startDate, endDate) {
    this.setVisualInput(startDate, endDate)
    this.setBeginInput(startDate)
    this.setEndInput(endDate)
    this.autoForward()
  }

  onManualChanged() {
    const dateStr = this.visualTarget.value.split(DATE_SEPARATOR)
    if (dateStr.length === 2) {
      this.setBeginInput(dateStr[0])
      this.setEndInput(dateStr[0])
    }
  }

  autoSubmit() {
    if (this.autoSubmitValue) { this.beginTarget.form.requestSubmit() }
  }

  autoLinkTo() {
    const params = {}
    params[this.visualTarget.name] = this.visualTarget.value
    params[this.beginTarget.name] = this.beginTarget.value
    params[this.endTarget.name] = this.endTarget.value
    linkTo(params, this.currentParamsValue)
  }

  autoForward() {
    this.plaintextValue ? this.autoLinkTo() : this.autoSubmit()
  }

  getDpLocale() {
    return Object.assign(DATERANGEPICKER_LOCALE, { format: this.dateFormatValue })
  }

  dateFormat(date) {
    return mFormat(date, this.dateFormatValue)
  }
}
