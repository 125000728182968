import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="popover"
export default class extends Controller {

  static popover;
  static values = {
    options: Object,
    show: Boolean
  };

  connect() {
    this.popover = new bootstrap.Popover(this.element, this.optionsValue);
    if(this.showValue === true) {
      this.show();
    }
  }

  show() {
    this.popover.show();
  }
}