import { Controller } from "@hotwired/stimulus"
import AirDatepicker, {
  localeZh,
  extendMonthlyRange,
  toggleRangeSelected
} from 'libs/air-datepicker'
import { DEFAULT_VALUES_MONTHLY } from './consts'
import {
  mParseDate,
  mFormat,
  isSameMonth,
  linkTo,
  isPresent } from 'utils'

const DATE_PARAM_FORMAT = 'yyyy-MM-DD'
const DATE_SEPARATOR = ' ~ '

// Connects to data-controller="date-picker--month"
export default class extends Controller {
  static targets = [ "visual", "begin", "end", "icon" ]
  static values = {
    range: { type: Boolean, default: false },
    ...DEFAULT_VALUES_MONTHLY
  }

  connect() {
    const dpOptions = {
      locale: localeZh,
      dateFormat: this.dateFormatValue,
      view: 'months',
      minView: 'months',
      inline: false,
      autoClose: true,
      multipleDatesSeparator: ' ~ ',
      range: this.rangeValue,
      selectedDates: isPresent(this.beginTarget.value) && isPresent(this.endTarget.value)
        ? [this.beginTarget.value, this.endTarget.value]
        : false,
      maxDate: this.allowFutureValue ? '' : moment(),
      minDate: this.allowPastValue ? '' : moment(),
      startDate: isPresent(this.endTarget.value)
        ? mParseDate(this.endTarget.value)
        : null,
      toggleSelected: this.rangeValue ? toggleRangeSelected : true,
      ...this.dpEvents()
    }
    this.dp = new AirDatepicker(this.visualTarget, dpOptions)
    // NOTICE: override hack
    if (this.rangeValue) {
      extendMonthlyRange(this.dp)
    }
    this.updateVisualSize()
  }

  iconTargetConnected() {
    this.bindIconAnchor('on')
  }

  iconTargetDisconnected() {
    this.bindIconAnchor('off')
  }

  dpEvents() {
    return {
      onHide: (isFinished) => {
        if (isFinished && this.rangeValue) {
          if (this.dp.selectedDates.length < 2) {
            this.resetDatepicker()
          }
        }
      },
      onSelect: ({ date }) => {
        if (this.rangeValue) {
          this.updateBeginEnd(moment(date[0]), moment(date[1]))
          if (Array.isArray(date) && date.length === 2) {
            this.updateVisualSize()
            this.autoForward()
          }
        } else {
          this.updateVisualSize()
          this.updateBeginEnd(moment(date), moment(date))
          this.autoForward()
        }
      },
    }
  }

  resetDatepicker() {
    this.dp.selectedDates = [this.beginTarget.value, this.endTarget.value]
    this.dp.init()
  }

  updateVisualSize() {
    // NOTICE: hack 還是會有些微誤差
    if (this.plaintextValue) {
      this.visualTarget.style.width = this.dp._getInputValue(this.dateFormatValue).length + 'ch'
    }
  }

  updateBeginEnd(beginDate, endDate) {
    this.beginTarget.value = beginDate.startOf('month').format(DATE_PARAM_FORMAT)
    this.endTarget.value = endDate.endOf('month').format(DATE_PARAM_FORMAT)
  }

  autoSubmit() {
    if (this.autoSubmitValue) { this.beginTarget.form.requestSubmit() }
  }

  autoLinkTo() {
    const params = {
      [this.visualTarget.name] : this.visualTarget.value,
      [this.beginTarget.name] : this.beginTarget.value,
      [this.endTarget.name] : this.endTarget.value
    }
    linkTo(params, this.currentParamsValue)
  }

  autoForward() {
    this.plaintextValue ? this.autoLinkTo() : this.autoSubmit()
  }

  manualDropdown() {
    this.visualTarget.focus()
  }

  // on | off
  bindIconAnchor(state) {
    const { iconTarget: el } = this
    const action = { on: 'addEventListener', off: 'removeEventListener' }[state]
    el[action]('click', this.openDatepicker.bind(this))
  }

  openDatepicker(e) {
    this.dp.show()
  }
}
