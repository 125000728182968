import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="attachments"
export default class extends Controller {
  static targets = [ "row", "deleteInput" ]

  remove(event) {
    event.preventDefault()
    this.rowTarget.hidden = true
    this.deleteInputTarget.value = true
  }
}
