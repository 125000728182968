import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-items"
export default class extends Controller {
  static targets = ["item", "visibleItem", "hiddenItem", "placeholder"]
  static values = {
    visibles: Array,
  }

  itemTargetConnected(target) {
    this._addVisibles(target.dataset.key)
    this._toggleLables()
  }

  visiblesValueChanged(visibles, old) {
    this._togglePlaceholder()
    this._toggleLables()
    this._updateIndex()
    this._updateItemsAttributesIndex()
  }

  append(html) {
    const key = $(html).data("key").toString()
    const found = this._findItem(key)

    if (found) {
      found.outerHTML = html
      this._showItem(found)
    } else {
      const elem = $(html).appendTo($(this.element)).get(0)
      this._showItem(elem)
    }
    this._addVisibles(key)
  }

  remove(e) {
    const { key } = e.target.dataset
    const item = this._findItem(key)

    $(item).bsHide().find("input.delete").val(true)

    this._hideItem(item)

    this._removeVisibles(key)
  }

  _findItem(key) {
    return this.itemTargets.find((target) => target.dataset.key == key)
  }

  _addVisibles(key) {
    if (this.visiblesValue.indexOf(key) == -1) {
      this.visiblesValue = this.visiblesValue.concat(key)
    }
  }

  _removeVisibles(key) {
    let visibles = this.visiblesValue.concat()
    let index = visibles.indexOf(key)
    if (index > -1) {
      visibles.splice(index, 1)
    }
    this.visiblesValue = visibles
  }

  _togglePlaceholder() {
    if (this.hasPlaceholderTarget && this.visiblesValue.length > 0) {
      this.placeholderTargets.forEach((target) => $(target).bsHide())
    } else {
      this.placeholderTargets.forEach((target) => $(target).bsShow())
    }
  }

  _toggleLables() {
    $(this.visibleItemTargets).find("label.form-label").bsHide()
    $(this.visibleItemTargets[0]).find("label.form-label").bsShow()
    $(this.hiddenItemTargets).find("label.form-label").bsHide()
  }

  _updateIndex() {
    $(this.hiddenItemTargets)
      .find("input[type='text']")
      .each((index, elem) => {
        elem.removeAttribute("tabindex")
      })

    this.visibleItemTargets.forEach((elem, rowIndex) => {
      $(elem)
        .find("input[type='text']")
        .each((inputIndex, input) => {
          input.setAttribute("tabindex", 1 + rowIndex + this.visibleItemTargets.length * inputIndex)
        })
    })
  }

  _updateItemsAttributesIndex() {
    const regex = new RegExp(/\[items_attributes\]\[(\d+?)\]/, "g")
    this.itemTargets.forEach((elem, index) => {
      $(elem)
        .find("input[name]")
        .each((_, input) => {
          input.setAttribute("name", input.getAttribute("name").replaceAll(regex, `[items_attributes][${index}]`))
        })
    })
  }

  _hideItem(element) {
    const targets = element.dataset.formItemsTarget.split(" ")

    if (targets.indexOf("visibleItem") >= 0) {
      targets.splice(targets.indexOf("visibleItem"), 1)
    }

    if (targets.indexOf("hiddenItem") == -1) {
      targets.push("hiddenItem")
    }
    element.dataset.formItemsTarget = targets.join(" ")
  }

  _showItem(element) {
    const targets = element.dataset.formItemsTarget.split(" ")

    if (targets.indexOf("hiddenItem") >= 0) {
      targets.splice(targets.indexOf("hiddenItem"), 1)
    }

    if (targets.indexOf("visibleItem") == -1) {
      targets.push("visibleItem")
    }
    element.dataset.formItemsTarget = targets.join(" ")
  }
}
