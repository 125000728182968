import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="feedback-items"
export default class extends Controller {
  static targets = [ "globalOrderId", "searchBtn"]

  connect() {
   this.showBtn()
  }
  
  showBtn() {
    const global_order_id = this.globalOrderIdTarget.value
    if (global_order_id == "") {
      $(this.searchBtnTarget).bsHide()
    } else {
      const path = this.searchBtnTarget.getAttribute('data-href')
      this.searchBtnTarget.setAttribute('href', path + '?global_order_id='+global_order_id)
      $(this.searchBtnTarget).bsShow()
    }
  }
}