import { Controller } from "@hotwired/stimulus"
import {
  DEFAULT_VALUES,
  DATERANGEPICKER_LOCALE,
  DATE_FORMAT
} from './consts'
import { mParseDate, linkTo } from 'utils'

// Connects to data-controller="date-picker--date"
export default class extends Controller {
  static targets = [ 'date' ]
  static values = {
    ...DEFAULT_VALUES
  }

  dateTargetConnected() {
    this.updateDate(mParseDate(this.dateTarget.value))
  }

  dateTargetConnected(elem) {
    this.updateDateSize()
    elem.addEventListener('change', this.updateDateSize.bind(this))
  }

  dateTargetDisconnected(elem) {
    elem.removeEventListener('change', this.updateDateSize.bind(this))
  }

  connect() {
    // init datepicker
    const opts = {
      locale: this.getDpLocale(),
      autoUpdateInput: this.autoUpdateInputValue || false,
      singleDatePicker: true,
      alwaysShowCalendars: true,
      startDate: this.dateTarget.value || moment(),
      autoApply: true,
      drops: 'auto',
      showDropdowns: true,
      maxDate: !this.allowFutureValue && moment(),
      minDate: !this.allowPastValue && moment()
    }
    this.dp = new DateRangePicker(this.dateTarget, opts, this.onApply.bind(this))
  }

  updateDate(date) {
    if (date && typeof date != 'undefined' && date.isValid()) {
      this.dateTarget.value = date.format(this.dateFormatValue)
    }
  }

  updateDateSize() {
    // NOTICE: hack 還是會有些微誤差
    if (this.plaintextValue) {
      this.dateTarget.style.width = this.dateTarget.value.length + 'ch'
    }
  }

  onApply(date) {
    this.updateDate(date)
    this.updateDateSize(date)
    this.autoForward()
  }

  autoSubmit() {
    if (this.autoSubmitValue) { this.dateTarget.form.requestSubmit() }
  }

  autoLinkTo() {
    const params = {}
    params[this.dateTarget.name] = this.dateTarget.value
    linkTo(params, this.currentParamsValue)
  }

  autoForward() {
    this.plaintextValue ? this.autoLinkTo() : this.autoSubmit()
  }

  getDpLocale() {
    return Object.assign(DATERANGEPICKER_LOCALE, { format: this.dateFormatValue })
  }
}
