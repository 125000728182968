import { Controller } from "@hotwired/stimulus"

const PROPS = {
  country: String,
  zipcode: String,
  state: String,
  city: String,
  addr1: String,
  addr2: String,
  recipient: String,
  mobile: String,
}

// Connects to data-controller="address"
export default class extends Controller {
  static outlets = [ "modal" ]
  static values = {
    formName: String,
    ...PROPS,
  }

  fill() {
    Object.keys(PROPS).forEach((key) => {
      $(this._inputSelector(key)).val(this[`${key}Value`])
    })
    this.modalOutlet.close()
  }

  _inputSelector(attrName) {
    if (this.hasFormNameValue) {
      return `[name="${this.formNameValue}[${attrName}]"]`
    } else {
      return `[name*="[${attrName}]"]`
    }
  }
}
