import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="upload-image"
export default class extends Controller {
  static targets = [ "previewImg" ]

  preview(e) {
    var getPreviewElem = () => $(this.previewImgTarget).find('img')[0]
    var originalSrc = getPreviewElem().src
    const previewElem = getPreviewElem()

    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const base64 = e.target.result
        if (previewElem) {
          previewElem.src = base64
          $(this.previewImgTarget)
        }
      }
      reader.readAsDataURL(img);
    } else {
      previewElem.src = originalSrc
    }
  }
}
