// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import moment from "moment"
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "controllers";
import { TurboRequest } from "helpers";
import "libs";
import "stream_actions";

// Uncomment to disable Turbo Drive
// import { Turbo } from "@hotwired/turbo-rails";
// Turbo.session.drive = false;

// Uncomment to debug Turbo request
// document.addEventListener("turbo:before-fetch-request", (event) => {
//   console.log(event.target);
// });

window.moment = moment
window.TurboRequest = TurboRequest

ActiveStorage.start();
Turbo.setProgressBarDelay(200);


// Handle when turbo frame is missing
document.addEventListener("turbo:frame-missing", (event) => {
  const {
    detail: { response, visit },
  } = event;
  event.preventDefault();
  visit(response.url);
});
