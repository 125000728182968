import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-selection"
export default class extends Controller {
  static outlets = [ "form-items", "form-option" ]

  formOptionOutletConnected(controller, element) {
    controller.isAddedValue = (this.formItemsOutlet.visiblesValue.indexOf(controller.keyValue) >= 0)
  }

  add(formOption) {
    formOption.isAddedValue = true
    this.formItemsOutlet.append(formOption.htmlValue)
  }

  addAll() {
    this.formOptionOutlets.forEach(controller => this.add(controller))
  }
}
