import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-option"
export default class extends Controller {
  static targets = [ "addBtn", "addedBtn" ]
  static outlets = [ "form-selection" ]
  static values = {
    key: String,
    html: String,
    isAdded: Boolean
  }

  isAddedValueChanged(isAdded, old) {
    if (isAdded) {
      $(this.addBtnTarget).bsHide()
      $(this.addedBtnTarget).bsShow()
    } else {
      $(this.addedBtnTarget).bsHide()
      $(this.addBtnTarget).bsShow()
    }
  }

  add(e) {
    e.preventDefault()
    this.formSelectionOutlet.add(this)
  }
}
