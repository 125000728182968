export * from './moment'

export const isPresent = (val) => !!(val && val !== '')

export const isObjectAlike = (val) => (val instanceof Object)

export const isObject = (val) => isObjectAlike(val) && val.constructor.name === 'Object'

export const copy = (val) => {
  if (isArray(val)) {
    return [...val]
  }
  if (isObject(val)) {
    return Object.assign({}, val)
  }
  if (isObjectAlike(val)) {
    const cloned = {}
    forEach(val, (val, key) => cloned[key] = val)
    return cloned
  }
  throw 'Not supported'
}

export const isArray = Array.isArray

export const isString = (val) => (typeof val) === 'string'

export const forEach = (data, cb) => {
  if (isPresent(data.forEach)) {
    data.forEach(cb)
  } else if ( isObject(data) ) {
    Object.entries(data).forEach(([ key, val ]) => cb(val, key))
  } else {
    throw 'Not supported'
  }
}

const adjustParam = (val) => {
  if (val === null) {
    return ""
  }
  return val
}

// Examples:
//
//  case 1: https://eip.test?id=123&date=2022-01-01
//
//    linkTo({ date: '2022-02-28' })
//      => /?date=2022-02-28
//
//    linkTo({ date: '2022-02-28' }, true)
//      => /?id=123&date=2022-02-28
//
//    linkTo({ date: '2022-02-28', end: '2022-03-01' }, { id: 456, cate: 2 })
//      => /?id=456&cate=2&date=2022-02-28&end=2022-03-01
//
//  case 2: https://eip.test?id=123&date=2022-01-01
//
//    linkTo('https://eip.test/boardroom?id=456')
//      => /boardroom
//
//    linkTo('https://eip.test/boardroom?id=456', true)
//      => /boardroom?id=456&date=2022-01-01
//
export const linkTo = (where, carryParams = false) => {
  let url, params

  const getUrl = (dest) => {
    const url = new URL(dest), params = new URLSearchParams(url.search)
    return {
      url, params,
      setParam: (val, key) => params.set(key, adjustParam(val)),
      delParam: (_, key) => params.delete(key),
      toString: () => url.pathname + "?" + params.toString()
    }
  }

  if (isObject(where)) {
    url = getUrl(window.location)

    const presentParams = copy(url.params)

    if (isObject(carryParams)) {
      forEach(presentParams, url.delParam)
      forEach(carryParams, url.setParam)
    } else if (carryParams === false) {
      forEach(presentParams, url.delParam)
    }

    forEach(where, url.setParam)

  } else if (isString(where)) {
    url = getUrl(where)

    const presentParams = copy(url.params)

    let paramsToCarry = {}
    if (carryParams === true) {
      paramsToCarry = getUrl(window.location).params
    } else if (isObject(carryParams)) {
      paramsToCarry = carryParams
    }

    forEach(presentParams, url.delParam)
    forEach(paramsToCarry, url.setParam)
    forEach(presentParams, url.setParam)

  } else {
    throw 'Not supported (yet)'
  }

  Turbo.visit(url.toString())
}
