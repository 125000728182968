import { Controller } from "@hotwired/stimulus";

export const isChoicesBinded = (el) => !!el.dataset.choice

const CACHE_ID = '__choices_cache__'

// Connects to data-controller="choices"
export default class extends Controller {
  static values = {
    shoudldSort: { type: Boolean, default: false },
  };

  initialize() {
    if (this.isSelectOptionCached()) {
      this.recover()
    } else if (!this.isBinded()) {
      this.bind()
    }
  }

  connect() { }

  disconnect() { }

  recover() {
    const elemContainer = $(this.element).parents('.choices').get(0)

    if (elemContainer) {
      $(elemContainer).replaceWith($(this.$cachedSelectOption().text()))
      this.$cachedSelectOption().remove()
    }
  }

  bind() {
    const originSelectOptionHTML = this.element.outerHTML

    this.choices = new Choices(this.element, {
      classNames: { containerOuter: "choices is-invalid" },
      shouldSort: this.shoudldSortValue,
      callbackOnInit: () => {
        $(this.element).attr('data-pause', '')
      },
    })
    this.cacheSelectOptionHTML(originSelectOptionHTML)
  }

  isBinded() {
    return !!$(this.element).attr('data-binded')
  }

  $cachedSelectOption() {
    return $(this.element).prev(`#${CACHE_ID}`)
  }

  isSelectOptionCached() {
    return this.$cachedSelectOption().length > 0
  }

  cacheSelectOptionHTML(html) {
    $(`<div id="${CACHE_ID}"></div>`).hide().text(html).insertBefore(this.element)
  }
}
