import { createElement } from "./utils"

// EX:
//  requestTurboStream('POST', '/xxx/xxx', 'modal', { id: 1 })
//  requestTurboStream('POST', '/xxx/xxx', { id: 1 })
//  requestTurboStream('POST', '/xxx/xxx')
const requestTurboStream = (method, url, frame = null, params = {}) => {
  if (typeof frame !== 'string' && frame !== null) {
    params = frame; frame = null
  }

  const form = createElement(`
    <form action="${url}" method="${method}">
      <input type="submit">
    </form>
  `)
  Object.entries(params).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((v) => {
        form.append(createElement(`<input type="hidden" name="${key}" value="${v}" >`))
      })
    } else {
      form.append(createElement(`<input type="hidden" name="${key}" value="${val}" >`))
    }
  })

  if (frame) { form.dataset.turboFrame = frame }

  $(form).appendTo('body').get(0).requestSubmit()
}

// EX:
//  TurboRequest.post('/xxx/xxx', 'modal', { id: 1 })
//  TurboRequest.post('/xxx/xxx', { id: 1 })
//  TurboRequest.post('/xxx/xxx')
const TurboRequest = {
  send: requestTurboStream,
  post(url, frame = null, params = {}) {
    requestTurboStream('POST', url, frame, params)
  },
  get(url, frame = null, params = {}) {
    requestTurboStream('GET', url, frame, params)
  }
}
export default TurboRequest
