import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// 這是在頁面載入時直接觸發 TurboStream 的方式
// 目前用在一頁商店 Lazy Loading
export default class extends Controller {
  connect() {
    get(this.element.getAttribute('data-src'), {
      responseKind: "turbo-stream",
    });
  }
}
