import { isSameMonth } from 'utils'

const _multipleDatesToText = function(dates, separator) {
  return dates.every(val => val === dates[0])
    ? dates[0]
    : dates.join(separator)
}

export const toggleRangeSelected = ({ datepicker: { selectedDates }, date }) => {
  const togglingBegin = isSameMonth(selectedDates[0], date),
        togglingEnd   = isSameMonth(selectedDates[1], date)

  return selectedDates.length >= 2 && (togglingBegin || togglingEnd)
}

export const extendMonthlyRange = (dp) => {
  // 處理同月份
  dp._getInputValue = function(dateFormat) {
    let {selectedDates, opts} = this,
        {multipleDates, multipleDatesSeparator} = opts;

    if (!selectedDates.length) return '';

    let formatIsFunction = typeof dateFormat === 'function';

    let value = formatIsFunction
        ? dateFormat(multipleDates ? selectedDates : selectedDates[0])
        : selectedDates.map((date) => {
            return this.formatDate(date, dateFormat);
        });

    let datesIsEqual = selectedDates.every(val => val === selectedDates[0])

    value = formatIsFunction
        ? value
        : _multipleDatesToText(value, multipleDatesSeparator);

    // value = formatIsFunction
    //     ? value
    //     : value.join(multipleDatesSeparator);

    return value;
  }

  // 處理 EndDate toggle
  dp._handleAlreadySelectedDates = function(alreadySelectedDate, newSelectedDate) {
    const {range, toggleSelected} = this.opts;
    const isFunc = typeof toggleSelected === 'function';
    let shouldToggle = isFunc ? toggleSelected({datepicker: this, date: newSelectedDate}) : toggleSelected;

    if (range) {
      if (!shouldToggle) {
        // Add possibility to select same date when range is true
        if (this.selectedDates.length !== 2) {
          this.selectDate(newSelectedDate);
        }
      }
    }

    if (shouldToggle) {
      if (range && isSameMonth(this.selectedDates[1], newSelectedDate)) {
        this.unselectDate(this.selectedDates[1])
      } else {
        this.unselectDate(newSelectedDate);
      }
    } else {
      // Change last selected date to be able to change time when clicking on this cell
      this._updateLastSelectedDate(alreadySelectedDate);
    }
  }
}

