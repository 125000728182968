import AirDatepicker from 'air-datepicker';
import localeZh from 'air-datepicker/locale/zh';
import 'air-datepicker/air-datepicker.css';
import './index.css'

export * from './extend'

export { localeZh }

export default AirDatepicker
