import { StreamActions } from '@hotwired/turbo'
import { saveAs } from 'file-saver'
import { base64ToBlob, getContentType } from 'helpers'

StreamActions.close_modal = function() {
  const event = new Event("close_modal")
  window.dispatchEvent(event)
  // If you want to send the event somewhere besides the window
  // document.getElementById(this.target).dispatchEvent(event)
}

StreamActions.download = function () {
  let data = this.getAttribute('data')
  let ext = this.getAttribute('ext').toLowerCase()
  let filename = this.getAttribute('filename')

  const blob = base64ToBlob(data, getContentType(ext))
  saveAs(blob, filename + '.' + ext)
}

StreamActions.alert = function () {
  let message = this.getAttribute('message')
  alert(message)
}
