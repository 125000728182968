const createElement = (text) => $(text).get(0)

const MIME_TYPES = {
  pdf:   'application/pdf',
  doc:   'application/msword',
  dot:   'application/msword',
  docx:  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx:  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  docm:  'application/vnd.ms-word.document.macroEnabled.12',
  dotm:  'application/vnd.ms-word.template.macroEnabled.12',
  xls:   'application/vnd.ms-excel',
  xlt:   'application/vnd.ms-excel',
  xla:   'application/vnd.ms-excel',
  xlsx:  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xltx:  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xlsm:  'application/vnd.ms-excel.sheet.macroEnabled.12',
  xltm:  'application/vnd.ms-excel.template.macroEnabled.12',
  xlam:  'application/vnd.ms-excel.addin.macroEnabled.12',
  xlsb:  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
}
const getContentType = (extName) => MIME_TYPES[extName]

const base64ToBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export {
  createElement,
  getContentType,
  base64ToBlob
}
