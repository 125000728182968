import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="prpducts-mapper"
export default class extends Controller {
  static targets = [ "countInput", "deleteCheckbox", "row" ]

  connect() {
    if (this.alreadyInDom()) {
      // 已經重複存在的 Dom ID 移除
      this.element.remove()
      // 並把留下唯一存在的顯示（有可能是被刪除的狀態）
      document.querySelectorAll(`#${this.element.id}`).hidden = false
    }
  }

  increment(event) {
    event.preventDefault()
    this.countInputTarget.value = this.count() + 1
  }

  count() {
    return parseInt(this.countInputTarget.value || 0)
  }

  decrement(event) {
    event.preventDefault()
    this.countInputTarget.value = this.count() - 1
  }

  delete(event) {
    event.preventDefault()
    this.deleteCheckboxTarget.checked = true
    this.rowTarget.hidden = true
  }

  // Private

  alreadyInDom() {
    return document.querySelectorAll(`#${this.element.id}`).length > 1
  }
}
