import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="system--sp-fields"
export default class extends Controller {
  static targets = ["sp"];

  get by_signable() {
    return document.getElementById("by-signable");
  }

  get by_amount() {
    return document.getElementById("by-amount");
  }

  get by_static() {
    return document.getElementById("by-static");
  }

  connect() {
    this.update();
  }

  update() {
    switch (this.spTarget.value) {
      case "by_signable":
        if (this.by_amount) this.by_amount.style.display = "none";
        if (this.by_static) this.by_static.style.display = "none";
        if (this.by_signable) this.by_signable.style.display = "block";
        break;
      case "by_amount":
        if (this.by_signable) this.by_signable.style.display = "none";
        if (this.by_static) this.by_static.style.display = "none";
        if (this.by_amount) this.by_amount.style.display = "block";
        break;
      case "by_static":
        if (this.by_signable) this.by_signable.style.display = "none";
        if (this.by_amount) this.by_amount.style.display = "none";
        if (this.by_static) this.by_static.style.display = "block";
        break;
      default:
        console.log(`Sorry, not supported sp field.`);
    }
  }
}
