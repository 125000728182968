import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor"
export default class extends Controller {

  connect() {
    tinymce.remove();
    tinymce.init({
      selector: '.tinymce',
      menubar: false,
      toolbar: 'undo redo | bold italic underline | forecolor backcolor | numlist bullist | link removeformat | indent outdent | alignleft aligncenter alignright alignjustify | blocks fontsize ',
      toolbar_mode: 'wrap',
      plugins: 'link lists advlist',
      valid_elements: 'strong/b,div[align],br,span[style],p,ul,ol,li,a[href|target],sub,sup,em,i,font[color],h1,h2,h3,h4,h5,h6,blockquote,pre,code,hr',
    });
  }
}
