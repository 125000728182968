import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"
import '@yaireo/tagify/dist/tagify.css' // Tagify CSS

// Connects to data-controller="tags-field"
export default class extends Controller {

  static values = {
    suggestion: Array,
    hideSuggestion: Boolean,
    limit: Number,
    disableCreate: Boolean,
    remote: String,
    width: String,
    tagifyId: String,
  }

  connect() {
    let options = {}

    this.element.classList.remove("form-control")

    // 加入建議標籤
    const formattedWhitelist = this.hasSuggestionValue ?
      this.suggestionValue.map(item => ({value: item.name, id: item.id})) :
      []
    options['whitelist'] = formattedWhitelist

    options['dropdown'] = {
      // 是否顯示建議標籤
      enabled: this.hasHideSuggestionValue ? (this.hideSuggestionValue & 1) : 0,
      // 最多顯示幾個建議標籤
      maxItems: this.hasLimitValue ? this.limitValue : 999,
    }

    // 是否禁止新增標籤
    if(this.hasDisableCreateValue) {
      options['enforceWhitelist'] = !!this.disableCreateValue
    }

    // 設定 tagify id
    if(this.hasTagifyIdValue) {
      options['id'] = this.tagifyIdValue
    }

    this.tagify = new Tagify(this.element, options)

    // 設定寬度
    if(this.hasWidthValue) {
      this.tagify.DOM.input.style.minWidth = `${this.widthValue}px`
    }

    // 是否啟用遠端搜尋
    if(this.hasRemoteValue) {
      this.tagify.whitelist = null
      this.tagify.on('input focus', this.onInput.bind(this))
    }
  }

  onInput(e) {

    const value = e.detail.value || ''
    this.tagify.whitelist = null

    this.controller && this.controller.abort()
    this.controller = new AbortController()

    this.tagify.loading(true)

    fetch(this.remoteValue + `?q=${value}`, { signal: this.controller.signal })
      .then(response => response.json())
      .then((newWhitelist) => {
        this.tagify.whitelist = newWhitelist
        this.tagify.loading(false).dropdown.show(value)
      })
  }
}
